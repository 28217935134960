<template>
  <base-section id="tool-carousel" space="0">
    <v-container fluid>
      <v-row justify="center">
        <v-theme-provider dark class="customColor">
          <v-col class="accent py-5" cols="12" md="4">
            <v-responsive class="mx-auto" max-width="360">
              <base-title
                mobile-size="title"
                size="display-1"
                space="2"
                title="MavenX Ecosystem"
              />

              <base-divider color="primary" />

              <base-body :space="$vuetify.breakpoint.smAndDown ? 0 : 0">
                MavenX comprises a comprehensive suite of specialized tools,
                synergistically forming a cutting-edge ecosystem, empowering
                alternate asset managers to streamline their deal process
                seamlessly. MavenX enables faster deal execution, improved
                agility, and astute decision-making with real-time data
                analytics, leading to a substantial performance boost and
                competitive advantage in the financial landscape.
                <br /><br
              /></base-body>

              <v-item-group v-model="category">
                <template v-for="(tool, i) in tools">
                  <v-item :key="i" :value="tool.title">
                    <v-btn
                      :input-value="tool.title === category"
                      class="justify-start mb-1 white-text"
                      small
                      text
                      width="125"
                      @click="displayTool(tool)"
                    >
                      {{ tool.title }}
                    </v-btn>
                  </v-item>

                  <!-- <br
                    v-if="i < tools.length && $vuetify.breakpoint.mdAndUp"
                    :key="`br-${i}`"
                  /> -->
                </template>
              </v-item-group>
            </v-responsive>
          </v-col>
        </v-theme-provider>

        <v-col cols="12" md="8">
          <v-card flat tile class="px-5">
            <tools
              :title="displayTitle"
              :subtitle="displaySubtitle"
              :div="displayDiv"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionToolCarousel",

  components: {
    tools: () => import("@/views/sections/Tools"),
  },

  data: () => ({
    displayTitle: "SpreadSwift",
    displaySubtitle: "Intelligent financial spreading tool",
    displayDiv:
      "<div> <p class='text-justify'> SpreadSwift streamlines the credit analysis process by automating financial statement parsing and spreading, reducing the time and effort required for manual data entry. This allows financial institutions to complete credit analysis in a more efficient and timely manner, enabling them to make informed credit decisions faster. This can result in cost savings and improved analyst performance, while minimizing the risk of errors and inaccuracies. <br><li class='text-justify'>Automated parsing: Extract financial data from electronic documents with OCR and machine learning</li>  <li class='text-justify'>Standardized statements: Generate balance sheets, income statements, and cash flow statements adhering to industry standards</li><li class='text-justify'>Ratio analysis: Calculate and analyze financial ratios to assess creditworthiness and compare financial performance</li><li class='text-justify'>Time-saving automation: Reduce manual data entry for efficient credit analysis and faster decision-making.</li>      </div>",
    category: "All",
    tools: [
      {
        title: "SpreadSwift",
        subtitle: "Intelligent financial spreading tool",
        div: "<div> <p class='text-justify'> SpreadSwift streamlines the credit analysis process by automating financial statement parsing and spreading, reducing the time and effort required for manual data entry. This allows financial institutions to complete credit analysis in a more efficient and timely manner, enabling them to make informed credit decisions faster. This can result in cost savings and improved analyst performance, while minimizing the risk of errors and inaccuracies. <br><li class='text-justify'>Automated parsing: Extract financial data from electronic documents with OCR and machine learning</li>  <li class='text-justify'>Standardized statements: Generate balance sheets, income statements, and cash flow statements adhering to industry standards</li><li class='text-justify'>Ratio analysis: Calculate and analyze financial ratios to assess creditworthiness and compare financial performance</li><li class='text-justify'>Time-saving automation: Reduce manual data entry for efficient credit analysis and faster decision-making.</li>      </div>",
      },
      {
        title: "FinScape",
        subtitle: "Customizable Financial Template Creator",
        div: "<div> <p class='text-justify'> FinScape is a versatile financial template builder that enables users to create customized financial templates and map them according to industry, company, strategy, and other parameters, for more accurate financial analysis. FinScape can save users a significant amount of time and money by automating the financial analysis process while ensuring accuracy and precision. This can result in faster decision-making, cost savings, and improved financial performance. <br><li class='text-justify'>Customizable Templates: FinScape empowers users to create financial templates tailored to their specific needs and requirements.</li>  <li class='text-justify'>Industry-Specific Mapping: Map a company's financial templates as per the industry they are operating in, ensuring precise analysis.</li><li class='text-justify'>Company-Specific Mapping: Map the templates according to their company's financial structure, providing an accurate picture.</li><li class='text-justify'>Strategy-Specific Mapping: Map financial templates based on investment strategies, ensuring that financial analysis is aligned with strategy.</li>      </div>",
      },
      {
        title: "FlowZen",
        subtitle: "Workflow Builder",
        div: "<div> <p class='text-justify'>FlowZen is a versatile workflow builder designed for alternate asset managers, especially those involved in private credit deals. FlowZen can save alternate asset managers time and cost by streamlining their workflows and improving efficiency. With its customizable workflows, process mapping, task assignment, user access and permissions, and automated notifications, FlowZen can help managers identify bottlenecks and optimize their processes, resulting in faster deal closures and improved financial performance.<br><li class='text-justify'>Customizable Workflows: Users can create customized workflows tailored to their specific private credit deal management processes.</li>  <li class='text-justify'>Automated Notifications: Automated notifications and alerts to keep team members informed and on track, reducing the risk of delays.</li><li class='text-justify'>Multi-level approval: Hierarchical system of approvals ensures tasks are reviewed & approved by the appropriate personnel at each stage.</li><li class='text-justify'>Audit Trail: Creates a log of all activities and events within the workflow for the purpose of accountability, transparency, and compliance.</li>      </div>",
      },
      {
        title: "DocuHive",
        subtitle: "Document Management System",
        div: "<div> <p class='text-justify'>DocuHive is a document management platform that provides document capture, organization, collaboration, and security features. It integrates with SharePoint and DocuSign and offers custom workflows and audit trails to ensure compliance.<br><li class='text-justify'>Document Organization: Organize documents into folders and tag them with metadata for easy retrieval.</li>  <li class='text-justify'>Document Security: Ensure confidentiality and integrity of documents with security features like user authentication and access controls.</li><li class='text-justify'>Workflow Automation: Automate repetitive tasks and provide notifications and alerts for document-related workflows.</li><li class='text-justify'>SharePoint Integration: Access and manage documents from within the SharePoint interface.</li><li class='text-justify'>DocuSign Integration: Sign and send documents for signature electronically to reduce manual processes and paper-based workflows.</li><li class='text-justify'>Compliance and Audit Trails: Maintain compliance with audit trails of all document-related activities.</li>      </div>",
      },
      {
        title: "ESGenie",
        subtitle: "ESG Valuation Tool",
        div: "<div> <p class='text-justify'>ESGenie helps financial institutions and investors integrate ESG factors into credit analysis and investment decision-making processes. It uses advanced data analytics, modeling, and benchmarking to provide a comprehensive assessment of potential investments' ESG performance and quantify the financial impact of ESG factors on investment returns.<br><li class='text-justify'>Enables ESG integration in credit analysis and investment decisions with advanced data analytics and modeling.</li>  <li class='text-justify'>Allows users to aggregate, score, and benchmark ESG data from multiple sources.</li><li class='text-justify'>Provides scenario modeling to assess creditworthiness and identify ESG-related risks.</li><li class='text-justify'>Offers portfolio analysis, reporting, and integration with other investment tools and platforms.</li>      </div>",
      },
      {
        title: "EcoScope",
        subtitle: "ESG Reporting Tool",
        div: "<div> <p class='text-justify'>EcoScope is a state-of-the-art software platform that enables companies to streamline their ESG reporting processes and demonstrate their commitment to sustainability and responsible business practices. The platform leverages advanced data analytics and visualization capabilities to collect, verify, and report ESG-related data to internal and external stakeholders.<br><li class='text-justify'>Collects ESG data from various sources and ensures completeness for reporting.</li>  <li class='text-justify'>Verifies ESG data accuracy and reliability using automated and manual processes.</li><li class='text-justify'>Tracks ESG KPIs, such as carbon emissions and diversity metrics, for improvement.</li><li class='text-justify'>Generates customized ESG reports for different stakeholders.</li> <li class='text-justify'>Benchmarks ESG performance against industry peers and global standards, and visualizes data in charts and graphs</li>      </div>",
      },
      {
        title: "CreditPulse",
        subtitle: "Live news updates",
        div: "<div> <p class='text-justify'>Credit-Pulse is a cutting-edge news integration tool for finance professionals that provides real-time updates, customizable alerts, and valuable market insights. With detailed analysis and source citation for each news article, CreditPulse ensures regulatory compliance and helps you make informed decisions based on the latest trends and developments in your industry.<br><li class='text-justify'>Real-time news updates for your borrower and deal.</li>  <li class='text-justify'>Customizable alerts and sources for key topics and trends.</li><li class='text-justify'>Detailed analysis and source citation for each news article.</li><li class='text-justify'>Valuable market insights to make more informed decisions.</li><li class='text-justify'>Regulatory compliance updates to stay ahead of the curve.</li>      </div>",
      },
      {
        title: "LendVision",
        subtitle: "Post funding lender-borrower relationship management tool",
        div: "<div> <p class='text-justify'>LendVision is a digital platform designed for direct lending, private credit, and alternative asset managers. The platform offers comprehensive client relationship management tools that enable managers to efficiently manage their borrowers' accounts. LendVision provides a real-time view of client financial information, including account balances, transaction history, and investment holdings.<br><li class='text-justify'>Monitoring and Alerts: Stay informed about borrower's performance and receive notifications about important events or changes.</li>  <li class='text-justify'>Documentation: Easily upload, organize and access important documents, such as loan agreements and financial statements.</li><li class='text-justify'>Compliance Tracking: Tools for tracking and monitoring compliance to help managers comply with covenants and regulations.</li><li class='text-justify'>Borrower Engagement: Suite of engagement tools such as secure messaging and calendar scheduling to maintain regular communication</li>      </div>",
      },
    ],
  }),

  methods: {
    filter(card) {
      return card.category === this.category || this.category === "All";
    },
    displayTool(tool) {
      this.displayTitle = tool.title;
      this.displaySubtitle = tool.subtitle;
      this.displayDiv = tool.div;
    },
  },
};
</script>
<style scoped>
.customColor {
  background-color: black;
}
</style>
